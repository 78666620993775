import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VExpansionPanel,[_c(VExpansionPanelHeader,{attrs:{"expand-icon":'$expand'}},[_c('span',{staticClass:"text-body-2"},[_vm._v(_vm._s(_vm.title))])]),(_vm.items.length)?_c(VExpansionPanelContent,[_c(VList,{attrs:{"flat":""}},_vm._l((_vm.items),function(subItem,key){return _c(VListItem,{key:key},[_c(VListItemContent,{staticClass:"text-caption"},[_c('router-link',{attrs:{"to":subItem.link}},[_vm._v(" "+_vm._s(subItem.title)+" ")])],1)],1)}),1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }