import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,{attrs:{"icon":""},on:{"click":function($event){_vm.$vuetify.theme.dark = !_vm.$vuetify.theme.dark}}},[_c(VIcon,_vm._g(_vm._b({staticClass:"mx-3",attrs:{"dark":""}},'v-icon',attrs,false),on),[_vm._v("mdi-brightness-3")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('Theme')))])])}
var staticRenderFns = []

export { render, staticRenderFns }