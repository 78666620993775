import { VBtn } from 'vuetify/lib/components/VBtn';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VMenu,{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"text":"","small":""}},'v-btn',attrs,false),on),[_c('span',{staticClass:"font-weight-bold text-body-1"},[_vm._v(_vm._s(_vm.getNameFirstLetters))])])]}}])},[_c(VList,[_c(VListItem,{attrs:{"to":{ name: 'my-account' }}},[_c(VListItemTitle,[_c('span',[_vm._v(_vm._s(_vm.$t('My account')))])])],1),_c(VListItem,{on:{"click":_vm.logout}},[_c(VListItemTitle,[_c('span',[_vm._v(_vm._s(_vm.$t('Logout')))])])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }