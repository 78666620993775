import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{attrs:{"flat":""}},[_c(VCardTitle,{staticClass:"text-h6"},[_vm._v(" "+_vm._s(_vm.$t('Terms of Service'))+" ")]),_c(VDivider),_c(VCardText,{domProps:{"innerHTML":_vm._s(_vm.tos.contents)}}),_c(VDivider),_c(VCardActions,{staticClass:"px-3"},[_c(VCheckbox,{scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',[_c('span',[_vm._v(_vm._s(_vm.$t('I have read and agree to the FutureEcom')))]),_c('a',{attrs:{"target":"_blank","href":"//www.futureecom.com/terms-of-service/"}},[_vm._v(" "+_vm._s(_vm.$t('Terms of Service'))+" ")])])]},proxy:true}]),model:{value:(_vm.accepted),callback:function ($$v) {_vm.accepted=$$v},expression:"accepted"}}),_c(VSpacer),_c(VBtn,{attrs:{"loading":_vm.loading,"disabled":!_vm.accepted,"color":"primary","depressed":""},on:{"click":function($event){return _vm.$emit('onAccept', _vm.tos.version)}}},[_vm._v(" "+_vm._s(_vm.$t('Accept'))+" ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }